import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../static/constants'
//import { showToasterMessage } from '../../utils';

export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: COMMON_ACTIONS.RELATIONSHIP_DATA, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.RELATIONSHIP_DATA;
        }
      })
  }
}


export const fetchRelationshipByDomainName = (payload, props) => {
  return dispatch => {
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
      .then(res => {
        const { data } = res
        const load = { relationshipId:  data.relationshipId, source :payload.source }
        props.fetchDonationSetting(load);
        props.fetchRelationshipDataByRID(load);
      })
      .catch((err) => {
        console.log(err, "payload")
        if (!err.__isRetryRequest) {
         // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.RELATIONSHIP_DATA;
        }
      })
  }
}
